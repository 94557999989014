import React from "react"
import {
  Flex,
  Container,
  Button,
  HStack,
  Box,
  Image,
  Link,
} from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"

const Header = () => {
  return (
    <Container maxW="container.xl" padding={{ base: 0 }}>
      <Flex
        p={{ base: "16px", md: "30px" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <GatsbyLink to="/">
          <Image src="/logo.svg" />
        </GatsbyLink>
        <HStack spacing="10">
          <Link fontSize={{ base: "12px", md: "14px" }}>Documentation</Link>
          <Link fontSize={{ base: "12px", md: "14px" }}>Blog</Link>
          <Button
            variant="outline"
            colorScheme="green"
            display={{ base: "none", md: "flex" }}
            fontWeight="medium"
            fontSize="14px"
            color="black"
            bg="transparent"
            _hover={{}}
            px="32px"
            py="14px"
            h="50px"
          >
            Download Weld{" "}
            <Box ml="10px">
              <svg
                width="14"
                height="14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14 12.5V14H0v-1.5h14zM7.732 0v8.167l3.75-3.831L12.5 5.38 8.024 9.952l.005.005L7.01 11 1.5 5.37l1.019-1.044 3.757 3.84V0h1.456z"
                  fill="#191919"
                />
              </svg>
            </Box>
          </Button>
        </HStack>
      </Flex>
    </Container>
  )
}

export default Header
