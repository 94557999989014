import React from "react"

import {
  Box,
  Container,
  Flex,
  Stack,
  Link,
  Image,
  Text,
  Divider,
} from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"

const Footer = () => {
  return (
    <Box bg="#373738">
      <Container maxW={"container.xl"}>
        <Flex flexDirection="column">
          <Flex
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="space-between"
            alignItems={{ base: "center", md: "flex-start" }}
            pt={{ base: "40px", md: "71px" }}
            w="full"
          >
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "20px", md: "60px" }}
              alignItems={{ base: "center", md: "flex-start" }}
            >
              <Link
                as={GatsbyLink}
                fontSize={{ base: "14px", md: "16px" }}
                color="white"
              >
                Weld documentation
              </Link>
              <Link
                as={GatsbyLink}
                fontSize={{ base: "14px", md: "16px" }}
                color="white"
              >
                Read our blog
              </Link>
              <Link
                as={GatsbyLink}
                fontSize={{ base: "14px", md: "16px" }}
                color="white"
              >
                Terms & Privacy
              </Link>
              <Flex alignItems="flex-start">
                <Image src="/images/github-icon.svg" mr="10px" w="24px" />
                <Link
                  as={GatsbyLink}
                  fontSize={{ base: "14px", md: "16px" }}
                  color="white"
                >
                  Weld @ Github
                </Link>
              </Flex>
            </Stack>
            <Flex
              flexDirection="column"
              mt={{ base: "40px", md: "0px" }}
              alignItems={{ base: "center", md: "initial" }}
            >
              <Text
                fontSize={{ base: "14px", md: "16px" }}
                fontWeight="bold"
                color="white"
              >
                Weld.Dev
              </Text>
              <Text fontSize={{ base: "14px", md: "16px" }} color="white">
                127 Main St, Charlestown
              </Text>
              <Text fontSize={{ base: "14px", md: "16px" }} color="white">
                {" "}
                MA 02129, USA
              </Text>
            </Flex>
          </Flex>
          <Divider
            mt={{ base: "40px", md: "75px" }}
            orientation="horizontal"
            mb={{ base: "24px", md: "34px" }}
          />

          <Flex pb={{ base: "24px", md: "34px" }}
           justifyContent={{ base: "space-between", md: "flex-start" }}
          >
            <Image
              src="/images/logo-dark.svg"
              mr={{ base: "0px", md: "44px" }}
            />
            <Text
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="bold"
              color="#A1A1A1"
            >
              2021 © All Rights Reserved
            </Text>
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default Footer
